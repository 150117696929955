
import { Component, Prop, Vue } from 'vue-property-decorator';
import DttTypo from '@/components/Typo/index.vue';
import DttIcon from '@/components/Icon/index.vue';
import DttContextMenu from '@/components/ContextMenu/index.vue';
import { HintSize } from '@/components/Hint/types';
import { ViewportXArea, ViewportYArea } from '@/utils/screen';

@Component({
  name: 'DttHint',
  components: { DttContextMenu, DttIcon, DttTypo },
})
export default class Status extends Vue {
  @Prop({ type: String, default: 'md' }) size!: HintSize;
  @Prop({ type: String }) hint!: string;
  @Prop({ type: String, required: false }) iconColor?: string;
  @Prop({ type: String, default: 'information' }) iconName!: string;
  @Prop({ type: String }) areaY?: ViewportYArea;
  @Prop({ type: String }) areaX?: ViewportXArea;

  get textSize() {
    switch (this.size) {
      case 'sm':
        return 'xs';
      default:
        return this.size;
    }
  }

  get iconSize() {
    switch (this.size) {
      case 'lg':
        return '24px';
      case 'md':
        return '20px';
      case 'sm':
        return '16px';
      case 'xs':
        return '16px';
      default:
        return this.size;
    }
  }
}
