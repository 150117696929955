
import { Component, Emit, ModelSync, Prop, Vue } from 'vue-property-decorator';
import DttButton from '@/components/Button/index.vue';
import DttIcon from '@/components/Icon/index.vue';
import { ButtonsGroupItem } from '@/components/ButtonsGroup/types';

@Component({
  name: 'DttButtonsGroup',
  components: { DttButton, DttIcon },
})
export default class ButtonsGroup extends Vue {
  @ModelSync('value', 'change', { default: null })
  modelValue?: any;
  @Prop({ type: Array, default: () => [] }) options!: ButtonsGroupItem[];
  @Prop({ type: Boolean, default: false }) flat?: boolean;
  @Prop({ type: Boolean, default: false }) iconOnly?: boolean;

  isActiveOption(option: ButtonsGroupItem) {
    if (this.modelValue instanceof Array) {
      return this.modelValue.includes(option.value);
    }
    return option.value === this.modelValue;
  }

  @Emit()
  click(option: ButtonsGroupItem) {
    if (!(this.modelValue instanceof Array)) {
      this.modelValue = option.value;
      return option;
    }

    const id = this.modelValue.indexOf(option.value);
    if (id === -1) {
      this.modelValue.push(option.value);
    } else {
      this.modelValue.splice(id, 1);
    }
    this.$emit('change', this.modelValue);
  }
}
