
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IconName } from '@/components/Icon/types';
import DttIcon from '@/components/Icon/index.vue';
import Locale from '@/mixins/Locale';

interface AsideItem {
  label: string;
  to: string;
  icon: IconName;
}

@Component({
  name: 'DttLayout',
  components: { DttIcon },
  mixins: [Locale],
})
export default class Layout extends Vue {
  @Prop({ type: Array, default: () => [] }) asideItems!: AsideItem[];
  @Prop({ type: String }) settingsButton?: string;
  @Prop({ type: Boolean }) tooltips?: boolean;
  @Prop({ type: Boolean }) asideHidden?: boolean;

  isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
