
import { Component, Prop, Vue } from 'vue-property-decorator';
import DttTypo from '@/components/Typo/index.vue';
import DttIcon from '@/components/Icon/index.vue';
import DttTitle from '@/components/Title/index.vue';

@Component({
  name: 'DttValueCard',
  components: { DttTitle, DttIcon, DttTypo },
})
export default class ValueCard extends Vue {
  @Prop({ type: String, default: 'md' }) size?: string;
  @Prop({ type: [String, Array], required: false }) header?: string | string[];
  @Prop({ type: [String, Number, Array], required: false }) value?:
    | string
    | number
    | (string | number)[];
  @Prop({ type: String }) icon?: string;
  @Prop({ type: String }) valueColor?: string;
  @Prop({ type: String }) iconColor?: string;
  @Prop({ type: String }) state?: keyof typeof this.stateMapping;
  @Prop({ type: Boolean, default: false }) multipleValues!: boolean;

  stateMapping = {
    primary: {
      background: '#F2F6FF',
      'border-color': '#4F79D0',
    },
    danger: {
      background: '#FFEDED',
      'border-color': '#FF573F',
    },
  };

  get computedValueColor() {
    if (this.valueColor) return this.valueColor;
    return this.stateStyles?.['border-color'];
  }

  get stateStyles() {
    return this.state && this.stateMapping[this.state];
  }

  get iconStyles() {
    return {
      ...(!!this.iconColor && { background: this.iconColor }),
    };
  }
}
