
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import DttIcon from '@/components/Icon/index.vue';
import DttContextMenu from '@/components/ContextMenu/index.vue';
import DttInput from '@/components/Input/index.vue';
import { ContextMenuItem } from '@/components/ContextMenu/types';

@Component({
  name: 'DttPagination',
  components: { DttInput, DttContextMenu, DttIcon },
})
export default class Pagination extends Vue {
  @Prop({ type: Array, default: () => [20, 40, 60] }) sizes!: number[];
  @Prop({ type: Number, default: 20 }) size!: number;
  @Prop({ type: Number, default: 0 }) page!: number;
  @Prop({ type: [Number, String], default: 0 }) totalItems!: number;
  @Prop({ type: Number, default: 0 }) minPage!: number;
  @Prop({ type: Number, default: Number.POSITIVE_INFINITY }) maxPage!: number;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Boolean, default: false }) rowPagination!: boolean;

  get sizeOptions(): ContextMenuItem[] {
    return this.sizes.map((size) => ({
      label: size.toString(),
      value: size,
    }));
  }

  get activeSizeOption() {
    return this.sizeOptions.find((item) => item.value === this.size);
  }

  get isPrevDisabled() {
    return this.page === this.minPage || this.loading;
  }

  get isNextDisabled() {
    return this.page === this.maxPage || this.loading;
  }

  get pagesRow() {
    if (this.maxPage <= 8) {
      return Array.from({ length: this.maxPage }, (v, i) => i + this.minPage);
    }

    const startValues = [this.minPage];
    const lastValues = [this.maxPage];
    const nearbyValues = [];

    if (this.page < this.minPage + 2 || this.page > this.maxPage - 2) {
      startValues.push(this.minPage + 1, this.minPage + 2);
      lastValues.unshift(this.maxPage - 2, this.maxPage - 1);
    } else {
      nearbyValues.push(
        this.page - 2,
        this.page - 1,
        this.page,
        this.page + 1,
        this.page + 2
      );
    }

    const pagesArray: (number | string)[] = Array.from(
      new Set([...startValues, ...nearbyValues, ...lastValues])
    );

    const ellipsisPlaces: number[] = [];

    pagesArray.forEach((page, i) => {
      if (typeof page === 'number' && page - Number(pagesArray[i - 1]) > 1) {
        ellipsisPlaces.push(page);
      }
    });

    ellipsisPlaces.forEach((place) => {
      const index = pagesArray.indexOf(place);
      if (index >= 0) pagesArray.splice(index, 0, '...');
    });

    return pagesArray;
  }

  @Emit()
  refresh() {
    return true;
  }

  @Emit()
  prevClick() {
    return this.page - 1 > this.minPage ? this.page - 1 : this.minPage;
  }

  @Emit()
  nextClick() {
    return this.page + 1 < this.maxPage ? this.page + 1 : this.maxPage;
  }

  @Emit()
  sizeChange(size: ContextMenuItem | null) {
    return size?.value;
  }

  @Emit()
  pageChange(page: number) {
    return page >= this.minPage && page <= this.maxPage ? page : this.page;
  }
}
