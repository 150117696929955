
import { Component, Emit, Prop, Provide, Vue } from 'vue-property-decorator';
import TableColumn from '@/components/TableColumn/index.vue';
import DttIcon from '@/components/Icon/index.vue';
import DttCheckbox from '@/components/Checkbox/index.vue';
import Locale from '@/mixins/Locale';
import DttContextMenu from '@/components/ContextMenu/index.vue';
import TableGroup from '@/components/TableGroup/index.vue';

interface Sort {
  prop: string;
  direction: 'asc' | 'desc' | null;
}

type Size = 'sm' | 'md';

type DataItemCallback = (data: any, index: any) => any;

@Component({
  name: 'DttTable',
  components: {
    DttContextMenu,
    DttCheckbox,
    DttIcon,
    VNodeRenderer: {
      functional: true,
      render: (h: any, ctx: { props: { items: any } }) => ctx.props.items,
    },
  },
  mixins: [Locale],
})
export default class Table extends Vue {
  @Prop({ type: Array, default: [] }) data?: any[];
  @Prop({
    type: Object,
    default: () => ({
      prop: '',
      direction: null,
    }),
  })
  sort!: Sort;
  @Prop({ type: String, default: 'sm' }) headerSize!: Size;
  @Prop({ type: String, default: 'sm' }) cellSize!: Size;
  @Prop({ type: String, default: 'auto' }) maxHeight!: string;
  @Prop({ type: String, default: 'auto' }) height!: string;
  @Prop({ type: String, default: 'auto' }) minHeight!: string;
  @Prop() headerClass!: any;
  @Prop() footerClass!: any;
  @Prop({ type: Boolean, default: false }) hoverable!: boolean;
  @Prop({ type: Boolean, default: false }) noHeader!: boolean;
  @Prop({ type: Boolean, default: false }) selectable!: boolean;
  @Prop({ type: Boolean, default: false }) expandable!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: String, default: 'sm' }) selectSize!: Size;
  @Prop({ type: String, default: '' }) idProp!: string;
  @Prop({ type: Array, default: () => [] }) selectedRows!: any[];
  @Prop({ type: Function }) rowClass?: DataItemCallback;
  @Prop({ type: Function }) rowStyle?: DataItemCallback;

  @Provide('dtt-table-driver') driver = {
    add: this.addColumn,
    remove: this.removeColumn,
  };

  @Provide('dtt-table-driver-group') groupDriver = {
    add: this.addGroup,
    remove: this.removeGroup,
  };

  @Emit()
  sortChanged(state: Sort) {
    return state;
  }
  @Emit()
  rowClick(row: any) {
    return row;
  }
  @Emit()
  selectionChange(rows: any[]) {
    return rows;
  }

  columns: TableColumn[] = [];
  groups: TableGroup[] = [];
  expandedRowsIds: number[] = [];
  canScroll = false;
  wrapperOffsetTop = 0;
  wrapperScrollTop = 0;
  headerHeight = 0;
  requestAnimationId = 0;

  get columnsData() {
    return this.columns
      .map((column) => {
        return {
          props: column.state,
          headerRenderer: this.getColumnRenderer(column, 'header'),
          bodyRenderer: this.getColumnRenderer(column, 'body'),
          summaryRenderer: this.getColumnRenderer(column, 'summary'),
          filterRenderer: this.getColumnRenderer(column, 'filter'),
        };
      })
      .filter((c) => !!c);
  }

  get groupData() {
    const prepared = this.groups
      .map((group) => {
        const startIndex = this.columns.findIndex(
          (column) => group.$children[0] === column
        );
        return {
          startIndex,
          size: group.$children.length,
          props: group.state,
          renderer: group.$scopedSlots['header'],
        };
      })
      .filter((c) => !!c);
    const result = new Array(this.columns.length).fill('');
    let deleted = 0;
    prepared.forEach((group) => {
      result.splice(group.startIndex - deleted, group.size, group);
      deleted += group.size - 1;
    });
    return result;
  }

  get hasSomeGroup() {
    return this.groups.length;
  }

  get hasSomeSummary() {
    return this.columnsData.some((cd) => !!cd.summaryRenderer);
  }

  get wrapperEl() {
    return this.$refs.wrapper as HTMLDivElement;
  }

  get headerEl() {
    return this.$refs.header as HTMLDivElement;
  }

  get hasHeader() {
    return this.$slots.header;
  }

  get hasFooter() {
    return this.$slots.footer;
  }

  get hasHeaderOrFooter() {
    return this.hasHeader || this.hasFooter;
  }

  get hasNoHeaderAndNoFooter() {
    return !this.hasHeader && !this.hasFooter;
  }

  get iconSize() {
    switch (this.headerSize) {
      case 'sm':
        return '20px';
      case 'md':
        return '24px';
      default:
        return '20px';
    }
  }

  get selectColumnSize() {
    switch (this.selectSize) {
      case 'sm':
        return '50px';
      case 'md':
        return '75px';
      default:
        return '50px';
    }
  }

  get wrapperStyles() {
    return {
      maxHeight: this.maxHeight,
      height: this.height,
      minHeight: this.minHeight,
      overflowY: this.canScroll && !this.loading ? 'auto' : 'hidden',
      'overscroll-behavior':
        this.wrapperScrollTop > 0 && this.canScroll ? 'contain' : 'auto',
    };
  }

  get allRowsSelected() {
    if (!this.data?.length) return false;
    return this.data?.every((row) => this.isSelectedRow(row));
  }

  onToggleExpand(index: number) {
    const position = this.expandedRowsIds.findIndex((id) => id === index);
    if (position !== -1) {
      this.expandedRowsIds.splice(position, 1);
    } else {
      this.expandedRowsIds.push(index);
    }
  }

  onWheel() {
    this.headerHeight = this.headerEl?.getBoundingClientRect().height || 0;
    this.wrapperOffsetTop = this.wrapperEl.getBoundingClientRect().top;
    this.wrapperScrollTop = this.wrapperEl.scrollTop;
    this.canScroll = this.hasNoHeaderAndNoFooter || this.wrapperOffsetTop === 0;
    if (this.hasHeaderOrFooter)
      this.requestAnimationId = requestAnimationFrame(this.onWheel.bind(this));
  }

  isSelectedRow(row: any) {
    return (
      this.selectedRows.findIndex(
        (r) => this.getSelectedRowValue(r) === this.getSelectedRowValue(row)
      ) !== -1
    );
  }

  toggleRowSelection(row: any) {
    const index = this.selectedRows.findIndex(
      (r) => this.getSelectedRowValue(r) === this.getSelectedRowValue(row)
    );
    let newSelection = [...this.selectedRows];
    if (index === -1) {
      newSelection.push(row);
    } else {
      newSelection.splice(index, 1);
    }
    this.selectionChange(newSelection);
  }

  toggleAllSelections() {
    if (!this.data) return;
    const newSelection = [...this.selectedRows];
    if (this.allRowsSelected) {
      this.data.forEach((row) => {
        const index = newSelection.findIndex(
          (r) => this.getSelectedRowValue(r) === this.getSelectedRowValue(row)
        );
        if (index !== -1) {
          newSelection.splice(index, 1);
        }
      });
      this.selectionChange(newSelection);
      return;
    }
    const toAdd = this.data.filter((row) => {
      const index = newSelection.findIndex(
        (r) => this.getSelectedRowValue(r) === this.getSelectedRowValue(row)
      );
      return index === -1;
    });
    this.selectionChange([...newSelection, ...toAdd]);
  }

  getSelectedRowValue(row: any) {
    return this.idProp ? row[this.idProp] : row;
  }

  handleSort(prop: string) {
    if (this.sort.prop !== prop) {
      this.sortChanged({
        prop: prop,
        direction: 'asc',
      });
      return;
    }

    let newProp = prop;
    let newDirection: Sort['direction'] = null;
    switch (this.sort.direction) {
      case null:
        newDirection = 'asc';
        break;
      case 'asc':
        newDirection = 'desc';
        break;
      case 'desc':
        newDirection = null;
        newProp = '';
        break;
    }
    this.sortChanged({
      prop: newProp,
      direction: newDirection,
    });
  }

  getColumnRenderer(column: TableColumn, slotName: string) {
    return column.$scopedSlots[slotName];
  }
  getSortColor(prop: string) {
    return this.sort.prop === prop ? '#353A40' : '#939AA4';
  }
  getSortDirection(prop: string) {
    return this.sort.prop === prop ? this.sort.direction || '' : '';
  }

  addColumn(column: TableColumn) {
    this.columns.push(column);
  }

  addGroup(group: TableGroup) {
    this.groups.push(group);
  }

  removeColumn(column: TableColumn) {
    const index = this.columns.indexOf(column);
    if (index === -1) return;
    this.columns.splice(index, 1);
  }

  removeGroup(group: TableGroup) {
    const index = this.groups.indexOf(group);
    if (index === -1) return;
    this.groups.splice(index, 1);
  }

  async mounted() {
    this.onWheel();
  }

  async beforeDestroy() {
    cancelAnimationFrame(this.requestAnimationId);
  }
}
