
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'DttTableGroup',
})
export default class TableGroup extends Vue {
  @Prop({ required: true }) header?: string | any;
  @Prop({ type: String, default: 'left' }) headerAlign!: string;
  @Prop({ type: String, default: '' }) headerClass!: string;
  @Prop({ type: Boolean, default: false }) headerDivider!: boolean;

  @Inject('dtt-table-driver-group')
  driver!: {
    add: (group: TableGroup) => void;
    remove: (column: TableGroup) => void;
  };

  get thProps() {
    return {
      divider: this.headerDivider,
      align: this.headerAlign,
      class: this.headerClass,
    };
  }

  get state() {
    return {
      header: this.header,
      thProps: this.thProps,
    };
  }

  created() {
    this.driver.add(this);
  }

  beforeDestroy() {
    this.driver.remove(this);
  }
}
