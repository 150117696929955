export type ViewportYArea = 'top' | 'bottom';
export type ViewportXArea = 'left' | 'right';
export type ViewportArea = `${ViewportXArea}-${ViewportYArea}`;

const reverseAreaX = (area: ViewportXArea): ViewportXArea =>
  area === 'left' ? 'right' : 'left';

const reverseAreaY = (area: ViewportYArea): ViewportYArea =>
  area === 'top' ? 'bottom' : 'top';

export function getElementViewportArea(
  el: Element,
  prefer?: { x?: ViewportXArea; y?: ViewportYArea }
) {
  const { x, y } = el.getBoundingClientRect();
  const xArea: ViewportXArea = prefer?.x
    ? reverseAreaX(prefer?.x)
    : x > window.innerWidth / 2
    ? 'right'
    : 'left';
  const yArea: ViewportYArea = prefer?.y
    ? reverseAreaY(prefer?.y)
    : y > window.innerHeight / 2
    ? 'bottom'
    : 'top';
  return {
    area: `${xArea}-${yArea}` as ViewportArea,
    x: xArea,
    y: yArea,
  };
}
