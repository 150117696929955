var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"wrapper",staticClass:"dtt-table-wrapper",style:(_vm.wrapperStyles)},[(_vm.$slots.header)?_c('div',{ref:"header",staticClass:"dtt-table-wrapper__header",class:_vm.headerClass},[_vm._t("header")],2):_vm._e(),_c('table',{staticClass:"dtt-table"},[(_vm.selectable)?_c('col',{style:(`width: ${_vm.selectColumnSize}`)}):_vm._e(),_vm._l((_vm.columnsData),function(columnData,index){return _c('col',_vm._b({key:index},'col',{ ...columnData.props.colProp },false))}),(_vm.expandable && _vm.$scopedSlots.expandContent)?_c('col',{style:(`width: ${_vm.selectColumnSize}`)}):_vm._e(),_c('thead',{staticClass:"dtt-table__header",style:({ top: _vm.headerHeight + 'px' })},[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noHeader && _vm.hasSomeGroup),expression:"!noHeader && hasSomeGroup"}],staticClass:"dtt-table__header-row dtt-table__header-row--header-group"},[(_vm.selectable)?_c('th',{staticClass:"dtt-table__header-cell",class:['divider', _vm.headerSize]}):_vm._e(),_vm._l((_vm.groupData),function(group,index){return _c('th',{key:index,staticClass:"dtt-table__header-cell",class:[
            group?.props?.thProps?.divider && 'divider',
            group?.props?.thProps?.align,
            _vm.headerSize,
            group?.props?.thProps?.class,
          ],attrs:{"colspan":group.size}},[_c('div',{staticClass:"dtt-table__header-cell-inner"},[(group.renderer)?_c('VNodeRenderer',{attrs:{"items":group.renderer()}}):(group?.props?.header)?_c('span',[_vm._v(_vm._s(group?.props?.header))]):_vm._e()],1)])})],2),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noHeader),expression:"!noHeader"}],staticClass:"dtt-table__header-row"},[(_vm.selectable)?_c('th',{staticClass:"dtt-table__header-cell",class:['divider', _vm.headerSize]},[_c('div',{staticClass:"dtt-table__header-cell-inner checkbox"},[_c('DttCheckbox',{attrs:{"value":_vm.allRowsSelected,"size":_vm.selectSize},on:{"change":_vm.toggleAllSelections}})],1)]):_vm._e(),_vm._l((_vm.columnsData),function(columnData,index){return _c('th',{key:index,staticClass:"dtt-table__header-cell",class:[
            columnData.props.thProps.divider && 'divider',
            columnData.props.thProps.align,
            _vm.headerSize,
            columnData.props.thProps.class,
          ]},[_c('div',{staticClass:"dtt-table__header-cell-inner",class:{
              sortable: columnData.props.sortable,
            }},[(columnData.props.sortable)?_c('DttIcon',{staticClass:"dtt-table__header-icon-left dtt-table__header-icon-sort",class:[_vm.getSortDirection(columnData.props.prop)],attrs:{"name":"decrease","color":_vm.getSortColor(columnData.props.prop),"width":_vm.iconSize,"height":_vm.iconSize},nativeOn:{"click":function($event){columnData.props.sortable && _vm.handleSort(columnData.props.prop)}}}):_vm._e(),(columnData.headerRenderer)?_c('VNodeRenderer',{attrs:{"items":columnData.headerRenderer(columnData.props)},on:{"click":function($event){columnData.props.sortable && _vm.handleSort(columnData.props.prop)}}}):_c('span',{on:{"click":function($event){columnData.props.sortable && _vm.handleSort(columnData.props.prop)}}},[_vm._v(" "+_vm._s(columnData.props.header)+" ")]),(columnData.filterRenderer)?_c('DttContextMenu',{attrs:{"fixed":""},scopedSlots:_vm._u([{key:"content",fn:function(slotProps){return [_c('VNodeRenderer',{attrs:{"items":columnData.filterRenderer({
                      ...columnData.props,
                      ...slotProps,
                    })}})]}}],null,true)},[_c('DttIcon',{staticClass:"dtt-table__header-icon-right",attrs:{"name":columnData.props.filterActive ? 'filter-filled' : 'filter',"width":_vm.iconSize,"height":_vm.iconSize}})],1):_vm._e()],1)])}),(_vm.expandable && _vm.$scopedSlots.expandContent)?_c('th',{staticClass:"dtt-table__header-cell"}):_vm._e()],2)]),_c('tbody',{staticClass:"dtt-table__body"},[(_vm.data?.length)?[(_vm.hasSomeSummary)?_c('tr',{staticClass:"dtt-table__body-row",class:[_vm.hoverable && 'hoverable']},[_c('td',{staticClass:"dtt-table__body-cell summary-label"},[_vm._v(" "+_vm._s(_vm.t('kit-table.summary'))+" ")]),(_vm.selectable)?_c('td',{staticClass:"dtt-table__body-cell"}):_vm._e(),_vm._l((_vm.columnsData.slice(1)),function(columnData,columnIndex){return _c('td',{key:columnIndex,staticClass:"dtt-table__body-cell summary-text",class:[
              columnData.props.tdProps.align,
              _vm.cellSize,
              _vm.hoverable,
              columnData.props.tdProps.class,
            ]},[(columnData.summaryRenderer)?_c('VNodeRenderer',{attrs:{"items":columnData.summaryRenderer({
                  props: columnData.props,
                })}}):_vm._e()],1)})],2):_vm._e(),_vm._l((_vm.data),function(dataItem,dataIndex){return [_c('tr',{key:dataIndex,staticClass:"dtt-table__body-row",class:[
              _vm.hoverable && 'hoverable',
              _vm.rowClass && _vm.rowClass(dataItem, dataIndex),
            ],style:([_vm.rowStyle && _vm.rowStyle(dataItem, dataIndex)]),on:{"click":function($event){return _vm.rowClick(dataItem)}}},[(_vm.selectable)?_c('td',{staticClass:"dtt-table__body-cell checkbox",class:['divider', _vm.cellSize, _vm.hoverable],on:{"click":function($event){$event.stopPropagation();}}},[_c('DttCheckbox',{attrs:{"value":_vm.isSelectedRow(dataItem),"size":_vm.selectSize},on:{"change":function($event){return _vm.toggleRowSelection(dataItem)}}})],1):_vm._e(),_vm._l((_vm.columnsData),function(columnData,columnIndex){return _c('td',{key:columnIndex,staticClass:"dtt-table__body-cell",class:[
                columnData.props.tdProps.divider && 'divider',
                columnData.props.tdProps.align,
                _vm.cellSize,
                _vm.hoverable,
                columnData.props.tdProps.class,
              ]},[(columnData.bodyRenderer)?_c('VNodeRenderer',{attrs:{"items":columnData.bodyRenderer({
                    props: columnData.props,
                    dataItem: dataItem,
                    index: columnIndex,
                  })}}):[_vm._v(" "+_vm._s(dataItem[columnData.props.prop])+" ")]],2)}),(_vm.expandable && _vm.$scopedSlots.expandContent)?_c('td',{staticClass:"dtt-table__body-cell expand-column",class:['divider', _vm.cellSize, _vm.hoverable],on:{"click":function($event){$event.stopPropagation();return _vm.onToggleExpand(dataIndex)}}},[_c('dtt-icon',{staticClass:"expand-arrow",class:{ collapse: _vm.expandedRowsIds.includes(dataIndex) },attrs:{"name":"arrow-down"}})],1):_vm._e()],2),(_vm.$scopedSlots.expandContent)?_c('tr',{key:dataIndex + 'expand'},[_c('td',{attrs:{"colspan":"100"}},[_c('div',{class:[
                  'dtt-table__body-expanded-row',
                  { expanded: _vm.expandedRowsIds.includes(dataIndex) },
                ]},[_c('div',[(_vm.$scopedSlots.expandContent)?_c('VNodeRenderer',{attrs:{"items":_vm.$scopedSlots.expandContent({
                        props: _vm.$scopedSlots.props,
                        dataItem: dataItem,
                        index: dataIndex,
                      })}}):_vm._e()],1)])])]):_vm._e()]})]:[_c('tr',{staticClass:"dtt-table__body-row"},[_c('td',{staticClass:"dtt-table__body-cell empty",attrs:{"colspan":"100"}},[_vm._v(" "+_vm._s(_vm.t('kit-table.empty_data'))+" ")])])]],2)],2),(_vm.$slots.footer)?_c('div',{staticClass:"dtt-table-wrapper__footer",class:_vm.footerClass},[_vm._t("footer"),(false)?_vm._t("expandContent"):_vm._e()],2):_vm._e(),_c('div',[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }