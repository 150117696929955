import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  getElementViewportArea,
  ViewportArea,
  ViewportXArea,
  ViewportYArea,
} from '@/utils/screen';

@Component
export default class PanelMixin extends Vue {
  @Prop({ type: Boolean, default: false }) fixed!: boolean;
  @Prop({ type: String }) areaY?: ViewportYArea;
  @Prop({ type: String }) areaX?: ViewportXArea;

  TARGET_MARGIN = 10;
  isOpen = false;
  area: ViewportArea = 'left-top';
  requestAnimationId: number | null = null;

  replacePanel() {
    const target = this.$refs.target as HTMLElement;
    const panel = this.$refs.panel as HTMLElement;
    if (!target || !panel) {
      this.requestAnimationId = window.requestAnimationFrame(this.replacePanel);
      return;
    }
    const { width: panelWidth, height: panelHeight } =
      panel.getBoundingClientRect();
    const {
      left,
      top,
      bottom,
      width: targetWidth,
    } = target.getBoundingClientRect();
    const { x, y } = getElementViewportArea(this.$refs.target as Element, {
      x: this.areaX,
      y: this.areaY,
    });
    if (x === 'left') panel.style.left = `${left}px`;
    else panel.style.left = `${left - panelWidth + targetWidth}px`;
    if (y === 'top') panel.style.top = `${bottom + this.TARGET_MARGIN}px`;
    else panel.style.top = `${top - panelHeight - this.TARGET_MARGIN}px`;
    this.requestAnimationId = window.requestAnimationFrame(this.replacePanel);
  }

  outsideClickHandler(e: MouseEvent) {
    const el = this.$refs.el as Element;
    if (!el.contains(e.target as Node)) {
      this.isOpen = false;
    }
  }

  mounted() {
    document.addEventListener('click', this.outsideClickHandler);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.outsideClickHandler);
    if (this.requestAnimationId) {
      window.cancelAnimationFrame(this.requestAnimationId);
      this.requestAnimationId = null;
    }
  }
}
